import { Col, Divider, Row } from "antd";

export const Video = ({ propertyItem }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        textAlign: "center",
      }}
    >
      <Row
        gutter={[4, 4]}
        style={{ maxWidth: "853px", margin: "15px", width: "100%" }}
      >
        <Col xs={24} sm={24} md={24}>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src={propertyItem.video}
              title={propertyItem.title}
              allowfullscreen
              frameborder="0"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",

                maxHeight: "480px",
              }}
            ></iframe>
          </div>
        </Col>
        <Divider />
      </Row>
    </div>
  );
};
