import { Row, Col, Image } from "antd";

export const Images = ({ propertyItem }) => {
  // image guide
  // ogImages - 1200px by 630 px
  // gallery images 1024 by 768 px

  const listImages = propertyItem.images.map((data) => {
    return (
      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
        <Image style={{ maxWidth: "250" }} src={data.url} alt={data.alt} />
      </Col>
    );
  });
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
  
      }}
    >
      <Row gutter={[18, 18]} style={{ maxWidth: "1200px", padding: "5px" }}>
        <Image.PreviewGroup>{listImages}</Image.PreviewGroup>
      </Row>
    </div>
  );
};
