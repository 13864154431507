import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";
import { ListProperties } from "./pages/ListProperties";
import { PropertyPage } from "./pages/PropertyPage";
import { ReportTwentyTwo } from "./components/annualReport/reportTwentyTwo";
import { KollupitiyaMarket } from "./components/projects/kollupitiyaMarket";
import { ReportTwentyThree } from "./components/annualReport/reportTwentyThree";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<ListProperties />} />
        <Route exact path="/kmart" element={<KollupitiyaMarket />} />
        <Route
          exact
          path="/annual-report-2022-23"
          element={<ReportTwentyTwo />}
        />
        <Route
          exact
          path="/annual-report-2023-24"
          element={<ReportTwentyThree />}
        />
        <Route exact path="id/:propertyId" element={<PropertyPage />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
