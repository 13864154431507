import { Col, Row } from "antd";

export const KollupitiyaMarket = () => {
  return (
    <div className="main">
      <Row gutter={[18, 18]} style={{ margin: "15px" }}>
        <Col span={24}>
          <h1>Kollupitiya Market</h1>
          <h2>Introduction</h2>
          <b>
            <p>Map of the market</p>
          </b>
        </Col>
      </Row>
    </div>
  );
};
