import { Button, Row, Typography } from "antd";
import { propertyData } from "../components/properties/propertyData";
import { Card, Col } from "antd";
import { Link } from "react-router-dom";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";

export const ListProperties = () => {
  const { Meta } = Card;
  const { Paragraph } = Typography;
  const listPropertyCard = propertyData.map((data) => {
    return (
      <Col xs={24} sm={12} md={8}>
        <Link to={`id/${data.url}`} style={{ textDecoration: "none" }}>
          <Card
            hoverable
            cover={<img alt="example" src={data.thumbnailUrl} />}
            actions={[
              <Button
                copyable={{
                  text: `https://ew.lk/id/${data.url}`,
                }}
              >
                <Paragraph
                  copyable={{
                    text: `https://ew.lk/id/${data.url}`,
                    icon: "Copy Url",
                    tooltips: ["copy url", "copied!"],
                  }}
                ></Paragraph>
              </Button>,
              <Button type="link" disabled={data.available}>
                {data.available ? (
                  <p style={{ color: "green" }}>{"Available"}</p>
                ) : (
                  <p style={{ color: "red" }}>{"Not Available"}</p>
                )}
              </Button>,
            ]}
          >
            <Meta title={data.title} description={data.description} />
          </Card>
        </Link>
      </Col>
    );
  });

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <DynamicMetaTags
        ogTitle={"East West Properties"}
        ogUrl={"/"}
        ogDescription={
          "Listing of Properties available at East West Properties PLC"
        }
        ogImage={
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-ogImage-01.jpg"
        }
      />
      <Row gutter={[10, 10]} style={{ maxWidth: "1200px",padding:"10px" }}>
        {listPropertyCard}
      </Row>
    </div>
  );
};
