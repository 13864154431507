import { useEffect } from "react";
import { DynamicMetaTags } from "../../utils/dynamicMetaTags";

export const ReportTwentyThree = () => {
  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   // 👇️ redirects to an external URL
    //   window.location.replace("https://codefrontend.com");
    // }, 1000);

    // return () => clearTimeout(timeout);
    window.location.replace(
      "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/annual-reports/EAST-WEST-PROPERTIES-PLC+-ANNUAL-REPORT-+2023-2024.pdf"
    );
  }, []);
  return (
    <>
      <DynamicMetaTags
        ogTitle={"EWP | Annual Report 2023-2024"}
        ogUrl={"/"}
        ogDescription={
          "East West Properties PLC Annual Report for the financial year 2023-2024"
        }
        ogImage={
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/annual-reports/og-image-annual-report-2023-2024-01.png"
        }
      />
      Loading Annual Report 2023-24...
    </>
  );
};
