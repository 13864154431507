import { Col, Row, Space } from "antd";
import { Availability } from "../properties/availability";
import Sticky from "react-stickynode";

export const Title = ({ propertyItem, propertyId }) => {
  return (
    <Sticky top={47} innerZ={1000}>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Row
          gutter={[4, 4]}
          style={{
            width: "100%",
            padding: "15px",
            backgroundColor: "white",
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "2em" }}>{propertyItem.title}</h1>
            <a href="#google-map" style={{ textDecoration: "none" }}>
              <small style={{ fontSize: "12px" }}>
                {propertyItem.location}
              </small>
            </a>
          </Col>
          <Col span={24} style={{ textAlign: "center", marginBottom: "10px" }}>
            <Space>
              {/* <Button
              copyable={{
                text: `https://ew.lk/id/${propertyId}`,
              }}
            >
              <Paragraph
                copyable={{
                  text: `https://ew.lk/id/${propertyId}`,
                  tooltips: ["copy url", "copied!"],
                }}
              ></Paragraph>
            </Button> */}
              {/* 
            {propertyItem.propertyPdf && (
              <Button
                href={propertyItem.propertyPdf}
                target={"_blank"}
                rel="noreferrer"
              >
                Download PDF
              </Button>
            )} */}
              <Availability property={propertyItem.title} />
            </Space>
          </Col>
        </Row>
      </div>
    </Sticky>
  );
};
