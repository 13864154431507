export const propertyData = [
  {
    id: "0",
    available: true,
    thumbnailUrl:
      "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-ogImage-01.jpg",
    // url: "acuity",
    url: "marine-house",
    title: "Marine House",
    description:
      "The property is conviently located close to the libery plaza roundabout. The building has a gross area of 25,000sqft across 8 floors and a typical floor has 3200sqft + 21 car parking spaces. ",
    ogDescription:
      "Marine House Building, located near the Liberty Plaza roundabout, 3200sqft per floor, 7 storeys with 15 parking spaces   ",
    type: "Office",
    bathrooms: "",
    bedrooms: "",
    floor: 8,
    area: "3200+",
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "6months" },
      { id: 2, cat: "Advance", text: "3months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 3, cat: "Billed", text: "billed seperately" },
    ],
    lettingDetails: [
      { id: 1, cat: "Avaliable", text: "Immediately" },
      { id: 2, cat: "Type of Let", text: "Long Term" },
      { id: 3, cat: "Furnished", text: "No" },
    ],
    keyFeatures: [
      { id: 1, cat: "", text: "Backup Generator" },
      { id: 2, cat: "", text: "24 Hour Security Guard" },
      { id: 3, cat: "", text: "CCTV" },
      { id: 4, cat: "", text: "Parking" },
      { id: 5, cat: "", text: "Each floor 3200sqft" },
      { id: 6, cat: "", text: "Anchor Tenant - Acquity Partners" },
    ],
    documents: [
      {
        id: 1,
        docUrl:
          "https://www.dropbox.com/sh/420onycsmql0443/AACnSiAa6C0xiInbEC15PoRJa?dl=0",
        title: "Image Folder",
      },
      {
        id: 2,
        docUrl:
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/floor-plans/1st+Floor+Aquity+Buiding+(1).pdf",
        title: "1st floor plan - PDF",
      },
      {
        id: 3,
        docUrl:
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/floor-plans/2nd+Floor+Aquity+Buiding.pdf",
        title: "2nd floor plan - PDF",
      },
      {
        id: 4,
        docUrl:
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/floor-plans/Ground+floor+Aquity+Buiding+Plan-Model.pdf",
        title: "ground floor plan - PDF",
      },
    ],
    askingPrice: 180,
    parkingSpaces: 12,
    elevator: true,
    generator: true,
    video: "https://vimeo.com/showcase/10251615/embed",
    location: "No.53, Dharmapala Mawatha, Colombo 03, Kollupitya",
    propertyPdf: "",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
      {
        key: "2",
        alt: "1st floor view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-1st-floor.jpg",
      },
      {
        key: "3",
        alt: "floor plan",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building-floor-plan-gallery.jpg",
      },
      {
        key: "4",
        alt: "3rd floor view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acquity-3rd-floor-02.jpg",
      },
      {
        key: "5",
        alt: "3rd floor view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acquity-3rd-floor.jpg",
      },
      {
        key: "6",
        alt: "ground floor car park",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building-ground-floor.jpg",
      },
    ],
    matterport: "https://my.matterport.com/show/?m=68PkGc3wsQY",
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2954.3987557744076!2d79.85106139451936!3d6.912422157015676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25969e9f455ed%3A0xcf1a369fdc92547c!2sAcuity%20House%2C%2053%20Srimath%20Anagarika%20Dharmapala%20Mawatha%2C%20Colombo%2000300%2C%20Sri%20Lanka!5e0!3m2!1sen!2sjo!4v1678628199379!5m2!1sen!2sjo",
  },
  {
    id: "1",
    available: true,
    thumbnailUrl:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    title: "Warehouse No1",
    description:
      "kollupitiya roundabout, 7 floor office building with parking, each floor has 2100sqft ",
    type: "Warehouse",
    bathrooms: "",
    bedrooms: "",
    floor: "",
    area: "10,000 + sqft",
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "3months" },
      { id: 2, cat: "Advance", text: "3months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 3, cat: "Billed", text: "billed seperately" },
    ],
    lettingDetails: [
      { id: 1, cat: "Avaliable", text: "Immediately" },
      { id: 2, cat: "Type of Let", text: "Long Term" },
      { id: 3, cat: "Furnished", text: "No" },
    ],
    keyFeatures: [
      { id: 1, cat: "", text: "Backup Generator" },
      { id: 2, cat: "", text: "24 Hour Security Guard" },
      { id: 3, cat: "", text: "CCTV" },
      { id: 4, cat: "", text: "Parking" },
      { id: 5, cat: "", text: "Each floor 10,00sqft" },
    ],
    documents: [{ id: 1, docUrl: "https://google.com", title: "Gas Satefy" }],
    askingPrice: 180,
    parkingSpaces: 12,
    elevator: true,
    generator: true,
    video: [{ id: 0, title: "", url: "" }],
    location: "Peliyagoda",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    matterport: "https://my.matterport.com/show/?m=zauuyXonpCK",
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: "2",
    available: true,
    thumbnailUrl:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    title: "Warehouse No2",
    description: "This is the description",
    type: "Office",
    floor: 5,
    askingPrice: 180,

    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    video: [{ id: 0, title: "", url: "" }],
    commericalTerms: [{ id: 0, cat: "", text: "" }],
    lettingDetails: [{ id: 0, cat: "", text: "" }],
    keyFeatures: [{ id: 0, cat: "", text: "" }],
    documents: [
      {
        id: 1,
        docUrl: "",
        title: "",
      },
    ],

    parkingSpaces: 12,
    elevator: true,
    generator: true,
    location: "Peliyagoda",
    matterport: "https://my.matterport.com/show/?m=zauuyXonpCK",
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: 3,
    available: true,
    thumbnailUrl:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    title: "Warehouse No3",
    description: "This is the description",
    type: "Office",
    floor: 5,
    askingPrice: 180,
    commericalTerms: [{ id: 0, cat: "", text: "" }],

    parkingSpaces: 12,
    elevator: true,
    generator: true,
    video: [{ id: 0, title: "", url: "" }],
    location: "Peliyagoda",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    matterport: "https://my.matterport.com/show/?m=zauuyXonpCK",
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
];
